import { createStore } from 'vuex'
import api from './api'
import mutation from './mutation'
const Vue = require('vue')

export default createStore({
  state: {
    token: null,
    user: null,
    loading: false,
    admins: null,
    species: null
  },
  mutations: {
    ...mutation,
    init (state, data) {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          state[key] = data[key]
        }
      }
    },
    logout (state) {
      state.token = null
      state.user = null
      state.admins = null
      state.species = null
      delete Vue.api.defaults.headers.common.Authorization
    }
  },
  actions: {
    ...api,
    load ({ commit, state, dispatch }) {
      Vue.api.defaults.headers.common.Authorization = 'Bearer ' + state.token
      commit('init', { loading: true })
      setImmediate(async () => {
        try {
          await Promise.all([
            dispatch('getUser'),
            dispatch('getAdmins'),
            dispatch('getSpecies')])
        } catch (err) {
          return commit('logout')
        }
        commit('init', { loading: false })
      })
    }
  }
})

<template>
  <el-dialog
    title="Évaluations"
    v-model="visible"
    append-to-body
  >
    <Reviews v-loading="loading" v-model:reviews="reviews" :isProduct="isProduct" :isUser="isUser"/>
  </el-dialog>
</template>

<script>
import Reviews from '@/components/reviews.vue'

export default {
  props: {
    isProduct: {
      type: Boolean,
      default: false
    },
    isUser: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Reviews
  },
  data () {
    return {
      visible: false,
      loading: false,
      reviews: []
    }
  },
  methods: {
    open (id) {
      this.visible = true
      this.load(id)
    },
    load (id = null) {
      if (!id) {
        this.visible = false
        this.$notify({
          title: 'Erreur',
          message: 'Impossible de charger les évaluations',
          type: 'error'
        })
      } else {
        this.loading = true
        if (this.isProduct) {
          this.$store.dispatch('getProductReviews', id).then((reviews) => {
            this.reviews = reviews
            this.loading = false
          })
            .catch((err) => {
              console.log(err)
              this.loading = false
              this.visible = false
              this.$notify({
                title: 'Erreur',
                message: 'Impossible de charger les évaluations',
                type: 'error'
              })
            })
        } else {
          this.$store.dispatch('getUserReviews', id).then((reviews) => {
            this.reviews = reviews
            this.loading = false
          })
            .catch((err) => {
              console.log(err)
              this.loading = false
              this.visible = false
              this.$notify({
                title: 'Erreur',
                message: 'Impossible de charger les évaluations',
                type: 'error'
              })
            })
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
</style>

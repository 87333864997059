<template>
  <el-container direction="vertical" id="products">
    <h1>Produits</h1>
    <div class="header">
      <div class="search">
        <el-input
          class="searchbar"
          type="text"
          placeholder="Recherche"
          v-model="search"
          clearable
          @keyup.enter="resetPage"
          @clear="resetPage"
        />
        <el-button type="primary" class="search" @click="resetPage"
          >Rechercher</el-button
        >
      </div>
      <div class="buttons">
        <el-button type="info" :disabled="(products || []).length === 0" plain @click="exportProducts">Exporter</el-button>
      </div>
    </div>
    <div class="table-container" v-if="products.length > 0">
      <el-table
        v-loading="loadingData"
        ref="table"
        :data="products"
        style="width: 100%"
        sortable="custom"
        @sort-change="(e) => sortChange(e, 'from table')"
      >
        <el-table-column prop="id" label="Produit" width="200" sortable>
          <template #default="scope"> n° {{ scope.row.id }} </template>
        </el-table-column>
        <el-table-column prop="fisher" label="Producteur" sortable>
          <template #default="scope">
            <span v-if="scope.row.fisherId"
              ><router-link
                class="link-to-user"
                :to="'/users/' + scope.row.fisherId"
                >{{ scope.row.fisher.firstName }}
                {{ scope.row.fisher.lastName }}</router-link
              ></span
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="fisher" label="Mareyeur" sortable>
          <template #default="scope">
            <span v-if="scope.row.wholesalerId"
              ><router-link
                class="link-to-user"
                :to="'/users/' + scope.row.wholesalerId"
                >{{ scope.row.wholesaler.firstName }}
                {{ scope.row.wholesaler.lastName }}</router-link
              ></span
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="reviews"
          label="Nombre d'évaluation"
          width="200"
          sortable
        >
          <template #default="scope">
            <el-button
              text
              type="primary"
              v-if="scope.row.reviews > 0"
              @click="$refs.productReviews.open(scope.row.id)"
              class="clickable-reviews"
              >{{ scope.row.reviews }} évaluation{{
                scope.row.reviews > 1 ? "s" : ""
              }}</el-button
            >
            <span v-else>0 évaluation</span>
          </template>
        </el-table-column>
        <el-table-column prop="slaugtheredAt" label="Abattu le" sortable>
          <template #default="scope">
            <span v-if="scope.row.slaugtheredAt">{{
              formattedDate(scope.row.slaugtheredAt, "DD MMMM YYYY")
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" width="180">
          <template #default="scope">
            <div class="table-buttons">
              <el-button
                type="default"
                size="small"
                @click="$router.push('/products/' + scope.row.id)"
                >Voir</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-loading="loadingData"
        v-model:currentPage="page"
        background
        :page-size="25"
        layout="prev, pager, next"
        :total="count"
        hide-on-single-page
        @update:currentPage="load"
      />
    </div>
    <template v-else-if="products.length < 1 && loadingData">
      <p class="table-message">Chargement...</p>
    </template>
    <template v-else>
      <p class="table-message">Aucune donnée</p>
    </template>
    <ModalReviews ref="productReviews" :isProduct="true" />
  </el-container>
</template>

<script>
import { utils } from '@/mixins'
import { mapState } from 'vuex'
import ModalReviews from '@/components/modalReviews.vue'

export default {
  mixins: [utils],
  components: {
    ModalReviews
  },
  data () {
    return {
      products: [],
      search: '',
      page: 1,
      count: 0,
      loadingData: false,
      sort: {
        field: 'id',
        order: 'DESC'
      }
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.load(this.page)
      }
    }
  },
  computed: {
    ...mapState(['user', 'token'])
  },
  methods: {
    load (page) {
      this.loadingData = true
      this.$store
        .dispatch('getProducts', { search: this.search, page, sort: this.sort })
        .then((res) => {
          this.products = res.products
          this.count = res.count
          this.loadingData = false
        })
        .catch(() => {
          this.loadingData = false
          this.$notify({
            title: 'Erreur',
            message: "Les produits n'ont pas pu être chargés",
            type: 'error'
          })
        })
    },
    sortChange (data) {
      this.sort = {
        field: data.prop,
        order: data.order === 'ascending' ? 'ASC' : 'DESC'
      }
      this.$nextTick(() => {
        this.resetPage()
      })
    },
    resetPage () {
      this.page = 1
      this.load(1)
    },
    handleDelete (id) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer ce produit ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteProduct', { id }).then(() => {
            this.load(1)
          })
        })
        .catch((err) => {
          if (!err.toString().match(/cancel/)) {
            this.$notify({
              title: 'Erreur',
              message: "Ce produit n'a pas pu être supprimé",
              type: 'error'
            })
          }
        })
    },
    sortById (a, b) {
      if (parseInt(a.id.slice(0, -1)) < parseInt(b.id.slice(0, -1))) return -1
      if (parseInt(b.id.slice(0, -1)) > parseInt(a.id.slice(0, -1))) return 1
      return 0
    },
    exportProducts () {
      return window.open(`${process.env.VUE_APP_API || ''}/api/admin/products/export?search=${this.search}&sort=${this.sort.order}&sortBy=${this.sort.field}&token=${this.token}`)
    }
  },
  created () {
    if (this.$route.query.search) {
      this.search = this.$route.query.search
    }
    if (this.user) {
      this.load(this.page)
    }
  }
}
</script>

<style lang="scss" scoped>
#products {
  .header {
    display: flex;
    justify-content: space-between;
    .search {
      display: flex;
      width: 50%;
      gap: 20px;
      .searchbar {
        width: 50%;
      }
      .el-button {
        width: auto;
      }
    }
  }
  .table-container {
    margin-top: 20px;
  }
  .el-pagination {
    margin-top: 20px;
    justify-content: center;
  }
  .table-buttons {
    display: flex;
  }
  .table-message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .link-to-user {
    color: var(--el-color-primary);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .clickable-reviews {
    padding: 0;
  }
}
</style>

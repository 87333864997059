import _ from 'lodash'
import axios from 'axios'
import admin from './admin'
import user from './user'
import product from './product'
import species from './species'

const Vue = require('vue')

axios.defaults.baseURL = (process.env.VUE_APP_API || '') + '/api/admin/'
axios.defaults.timeout = 60000

Vue.api = axios.create()
export default _.merge(admin, user, product, species)

<template>
  <el-container id="user-add">
    <el-page-header @back="$router.push('/users')">
      <template #content>
        <span class="text-large font-600 mr-3">{{ title }}</span>
      </template>
    </el-page-header>
    <div class="content">
      <el-form :model="form" ref="userAdd" label-position="top" style="width: 400px" :rules="rules">
        <el-form-item prop="email" label="Email">
          <el-input v-model="form.email" placeholder="Email" :disabled="!!form.id"/>
        </el-form-item>
        <el-form-item prop="firstName" label="Prénom">
          <el-input v-model="form.firstName" placeholder="Prénom"/>
        </el-form-item>
        <el-form-item prop="lastName" label="Nom">
          <el-input v-model="form.lastName" placeholder="Nom"/>
        </el-form-item>
        <el-form-item prop="phone" label="Téléphone">
          <el-input v-model="form.phone" placeholder="Téléphone"/>
        </el-form-item>
        <el-form-item prop="type" label="Type d'utilisateur">
          <el-select v-model="form.type" style="width: 100%;" placeholder="Type d'utilisateur">
            <el-option label="Producteur" value="0"/>
            <el-option label="Mareyeur" value="1"/>
            <el-option label="Acheteur" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="updatePassword" label="Mot de passe">
          <el-input v-model="form.updatePassword" placeholder="Mot de passe"/>
        </el-form-item>
        <el-form-item prop="active" label="Status du compte">
          <el-switch
            v-model="form.active"
            class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
          />
        </el-form-item>
        <el-form-item class="button-container">
          <el-button type="primary" @click="submitForm('userAdd')">
            Valider
          </el-button>
        </el-form-item>
      </el-form>
      <el-card v-if="form.type === '0'">
        <div class="scans">
          <div class="header">
            <h3>Codes barres autorisés</h3>
            <el-button size="small" type="primary" @click="$refs.range.open()" :disabled="!form.id">
              <el-tooltip v-if="!form.id" class="box-item" content="L'utilisateur doit être créé" placement="bottom">
                Ajouter
              </el-tooltip>
              <span v-else>Ajouter</span>
            </el-button>
          </div>
          <div class="content" v-if="(form.scans || []).length > 0" >
            <el-table :data="form.scans" :loading="loading">
              <el-table-column label="De">
                <template #default="scope">
                  <span>{{ scope.row[0].slice(0, -1) }}<span class="code-grey">{{ scope.row[0].slice(-1)}}</span></span>
                </template>
              </el-table-column>
              <el-table-column label="à">
                <template #default="scope">
                  <span>{{ scope.row[1].slice(0, -1) }}<span class="code-grey">{{ scope.row[1].slice(-1)}}</span></span>
                </template>
              </el-table-column>
              <el-table-column label="Nombre" align="center">
                <template #default="scope">
                  <span>{{ getScanCount(scope.row[0], scope.row[1]) }}</span>
                </template>
              </el-table-column>
              <el-table-column align="right">
                <template #default="scope">
                  <el-button
                    size="small"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >
                    <el-icon color="danger"><DeleteFilled /></el-icon>
                </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <p v-else>Aucun code barre</p>
        </div>
      </el-card>
    </div>
    <AddRangeScans v-if="form.id" ref="range" :userId="form.id" @addedRange="(scans) => form.scans = scans"/>
  </el-container>
</template>

<script>
import { isMobilePhone } from 'validator'
import { mapState } from 'vuex'
import AddRangeScans from '../components/add-range'
import { utils } from '@/mixins'

export default {
  components: {
    AddRangeScans
  },
  mixins: [utils],
  data () {
    return {
      form: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        type: '',
        updatePassword: '',
        active: true
      },
      rules: {
        email: [
          { required: true, message: 'Ce champ est requis', trigger: 'blur' },
          { type: 'email', message: 'Merci d\'entrer une adresse email valide', trigger: ['blur', 'change'] }
        ],
        firstName: [
          { required: true, message: 'Ce champ est requis', trigger: 'blur' },
          { min: 3, message: 'Min. 3 caractères', trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: 'Ce champ est requis', trigger: 'blur' },
          { min: 3, message: 'Min. 3 caractères', trigger: 'blur' }
        ],
        phone: [
          { validator: this.checkPhone, trigger: 'blur' }
        ],
        type: [
          { required: true, message: 'Ce champ est requis', trigger: 'blur' }
        ],
        updatePassword: [
          { required: this.$route.params.id === 'add', message: 'Ce champ est requis', trigger: 'blur' }
        ]
      },
      title: 'Ajouter un utilisateur',
      addRange: true,
      formRange: {
        first: 0,
        last: 0
      },
      loading: false
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.load()
      }
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    checkPhone (rule, value, callback) {
      if (value && !isMobilePhone(value, 'fr-FR', { strictMode: true })) {
        callback(new Error('Numéro de téléphone invalide (+33)'))
      } else {
        callback()
      }
    },
    submitForm (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          if (this.$route.params.id === 'add') {
            this.$store.dispatch('createUser', { ...this.form, password: this.form.updatePassword, updatePassword: undefined })
              .then((res) => {
                this.$refs[ref].resetFields()
                this.$notify({
                  title: 'Succès',
                  message: 'Utilisateur ajouté',
                  type: 'success'
                })
              })
              .catch((err) => {
                if (err.message.toString().match(/409/)) {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Cet email existe déjà.',
                    type: 'error'
                  })
                } else if (err.message.toString().match(/422/)) {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Email ou numéro de téléphone invalide',
                    type: 'error'
                  })
                } else {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Une erreur est survenue',
                    type: 'error'
                  })
                }
              })
          } else {
            this.$store.dispatch('updateUser', this.form)
              .then(() => {
                this.$notify({
                  title: 'Succès',
                  message: 'Utilisateur modifié',
                  type: 'success'
                })
              })
              .catch((err) => {
                if (err.message.toString().match(/422/)) {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Email ou numéro de téléphone invalide',
                    type: 'error'
                  })
                } else {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Une erreur est survenue',
                    type: 'error'
                  })
                }
              })
          }
        }
      })
    },
    load () {
      const id = this.$route.params.id
      if (id && id !== 'add') {
        this.$store.dispatch('getSingleUser', { id })
          .then((user) => {
            this.form = { ...user }
            this.title = 'Modifier l\'utilisateur'
          })
          .catch(() => {
            this.$notify({
              title: 'Erreur',
              message: 'Cet utilisateur n\'a pas pu être trouvé',
              type: 'error'
            })
          })
      }
    },
    handleDelete (index, row) {
      this.loading = true
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer ces codes barres ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteRangeScan', { userId: this.form.id, index, scans: row })
            .then((scans) => {
              this.form.scans = scans
              this.loadind = false
            })
        })
        .catch((err) => {
          if (!err.toString().match(/cancel/)) {
            this.$notify({
              title: 'Erreur',
              message: 'Impossible de supprimer ces codes barres',
              type: 'error'
            })
          }
          this.loading = false
        })
    }
  },
  mounted () {
    if (this.user) {
      this.load()
    }
  }
}
</script>

<style lang='scss'>
  #user-add {
    flex-direction: column;
    .el-page-header {
      margin: 20px 0;
    }
    .content {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 80px;
      .el-form {
        .el-form-item.button-container .el-form-item__content{
          display: flex;
          justify-content: center!important;
          align-items: center;
        }
      }
      .el-card {
        .el-card__body {
          padding: 20px 40px;
        }
      }
      .scans {
        width: 400px;
        .code-grey {
          color: lightgray;
        }
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
</style>

<template>
  <div>
    <h1>Connexion</h1>
    <el-form id="login-form" ref="loginForm" size="small" :model="form" label-position="top" label-width="130px">
      <el-row :gutter="30">
        <el-col>
          <el-form-item label="Email" prop="email">
            <el-input v-model="form.email"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col>
          <el-form-item label="Mot de passe" prop="password">
            <el-input v-model="form.password" show-password/>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="align-center" v-if="error">
        <p class="form-error">Identifiants invalides</p>
      </div>
      <div class="align-center">
        <el-button
          type="primary"
          :loading="loading"
          round
          @click.prevent="submit"
        >
          Connexion
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      loading: false,
      error: false
    }
  },
  methods: {
    submit () {
      this.loading = true
      this.error = false
      if (this.form.email === '' || this.form.password === '') {
        this.error = true
        this.loading = false
        return
      }
      this.$store.dispatch('signin', this.form)
        .catch(() => {
          this.error = true
        })
      this.loading = false
    }
  }
}
</script>

<style type="scss" scoped>
#login-form {
  width: 400px;
  margin: 0 auto;
}
#login-form .el-input {
  width: 100%;
}
.form-error {
  color: #F56C6C;
  font-size: 14px;
  line-height: 1;
  padding-top: 4px;
}
</style>

<template>
  <el-container id="product-edit">
    <el-page-header @back="$router.push('/products')">
      <template #content>
        <span class="text-large font-600 mr-3">Modifier un produit</span>
      </template>
    </el-page-header>
    <div class="content">
      <div class="form-container">
        <el-form :model="form" ref="productEdit" label-position="top" style="width: 400px" :rules="rules">
          <div class="map-container" v-if="canLoadMap">
              <GMapMap
                v-if="form.location.latitude && form.location.longitude"
                :center="position"
                :zoom="10"
                style="width: 100%; height: 300px"
                class="map"
              >
                  <GMapMarker
                    :position="position"
                  />
              </GMapMap>
          </div>
          <el-form-item prop="id" label="Numéro de produit">
            <el-input v-model="form.id" disabled/>
          </el-form-item>
          <el-form-item prop="especeId" label="Espèce">
            <el-select v-model="form.especeId" style="width: 100%;" placeholder="Espèce" filterable>
              <el-option
                v-for="(item, index) in species"
                :key="index"
                :value="item.fao"
                :label="`(${item.fao}) ${item.frNames}`"
                :selected="form.speciesId === item.fao"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="fisherId" label="Producteur">
            <el-select v-model="form.fisherId" style="width: 100%;" placeholder="Producteur">
              <el-option
                v-for="fisher in getSalersType('0')"
                :label="fisher.firstName + ' ' + fisher.lastName"
                :value="fisher.id"
                :key="fisher.id"
                :selected="fisher.id === form.fisherId"
              />
              <el-option value="">Aucun</el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="wholesalerId" label="Mareyeur">
            <el-select v-model="form.wholesalerId" style="width: 100%;" placeholder="Mareyeur">
              <el-option
              v-for="wholesaler in getSalersType('1')"
              :label="wholesaler.firstName + ' ' + wholesaler.lastName"
              :value="wholesaler.id"
              :key="wholesaler.id"
              :selected="wholesaler.id === form.wholesalerId"
              />
              <el-option value="" label="Aucun"/>
            </el-select>
          </el-form-item>
          <el-form-item prop="date" label="Pêché le">
            <el-date-picker
              v-model="form.date"
              type="date"
              format="DD/MM/YYYY"
              value-format="X"
              placeholder="Date de pêche"
            />
          </el-form-item>
          <el-form-item prop="slaugtheredAt" label="Abattu le">
            <el-date-picker
              v-model="form.slaugtheredAt"
              type="datetime"
              format="DD/MM/YYYY HH:mm"
              value-format="X"
              placeholder="Date d'abattage"
            />
          </el-form-item>
          <el-form-item class="button-container">
            <el-button type="primary" @click="submitForm('productEdit')">
              Valider
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-container>
</template>

<script>
import { utils } from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [utils],
  data () {
    return {
      salers: [],
      form: {
        id: '',
        wholesalerId: '',
        fisherId: '',
        especeId: '',
        date: null,
        slaugtheredAt: null
      },
      rules: {
        especeId: [{ required: true, message: 'Ce champ est requis', trigger: 'blur' }],
        slaugtheredAt: [{ required: true, message: 'Ce champ est requis', trigger: 'blur' }]
      },
      position: {
        lat: null,
        lng: null
      },
      canLoadMap: false
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.load()
      }
    }
  },
  computed: {
    ...mapState(['user', 'token', 'species'])
  },
  methods: {
    submitForm (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.$store.dispatch('updateProduct', {
            ...this.form,
            fisherId: this.form.fisherId && this.form.fisherId.length > 0 ? this.form.fisherId : null,
            wholesalerId: this.form.wholesalerId && this.form.wholesalerId.length > 0 ? this.form.wholesalerId : null
          }).then(() => {
            this.$notify({
              title: 'Succès',
              message: 'Le produit a été modifié avec succès',
              type: 'success'
            })
          })
            .catch(() => {
              this.$notify({
                title: 'Erreur',
                message: 'Impossible d\'enregistrer le produit',
                type: 'error'
              })
            })
        }
      })
    },
    load () {
      const id = this.$route.params.id
      if (id) {
        this.$store.dispatch('getProduct', id)
          .then((product) => {
            this.form = { ...this.form, ...product }
            if (product.location.longitude && product.location.latitude) {
              this.position.lat = product.location.latitude
              this.position.lng = product.location.longitude
              this.canLoadMap = true
            }
            this.getSalers()
          })
          .catch(() => {
            this.$notify({
              title: 'Erreur',
              message: 'Ce produit n\'a pas pu être trouvé',
              type: 'error'
            })
          })
      } else {
        this.$notify({
          title: 'Erreur',
          message: 'Ce produit n\'a pas pu être trouvé',
          type: 'error'
        })
        this.$router.push('/products')
      }
    },
    getSalers () {
      this.$store.dispatch('getUsersSalers').then((salers) => {
        this.salers = salers
      }).catch(() => {
        this.$notify({
          title: 'Erreur',
          message: 'Impossible de charger les producteur et mareyeurs',
          type: 'error'
        })
      })
    },
    getSalersType (type) {
      return this.salers.filter((s) => s.type === type && s.active)
    }
  },
  mounted () {
    if (this.user) {
      this.load()
    }
  }
}
</script>

<style lang='scss' scoped>
  #product-edit {
    flex-direction: column;
    .el-page-header {
      margin: 20px 0;
    }
    .content {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      .form-container {
        display: flex;
        justify-content: center;
        .el-form {
          .map-container {
            margin-bottom: 20px;
          }
          .el-form-item.button-container .el-form-item__content{
            display: flex;
            justify-content: center!important;
            align-items: center;
          }
        }
      }
    }
  }
</style>

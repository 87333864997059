<template>
  <el-container id="species-add">
    <el-page-header @back="$router.push('/species')">
      <template #content>
        <span class="text-large font-600 mr-3">{{ title }}</span>
      </template>
    </el-page-header>
    <div class="content">
      <el-form :model="form" ref="speciesAdd" label-position="top" style="width: 400px" :rules="rules">
        <el-form-item prop="fao" label="Code FAO">
          <el-input v-model="form.fao" placeholder="Code FAO"/>
        </el-form-item>
        <el-form-item prop="name" label="Nom scientifique">
          <el-input v-model="form.name" placeholder="Nom scientifique"/>
        </el-form-item>
        <el-form-item prop="asfis" label="Nom ASFIS">
          <el-input v-model="form.asfis" placeholder="Nom ASFIS"/>
        </el-form-item>
        <el-form-item prop="frNames" label="Dénomination">
          <el-input v-model="form.frNames" placeholder="Dénomination"/>
        </el-form-item>
        <el-form-item class="button-container">
          <el-button type="primary" @click="submitForm('speciesAdd')">
            Valider
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import { utils } from '@/mixins'

export default {
  mixins: [utils],
  data () {
    return {
      form: {
        name: '',
        asfis: '',
        fao: '',
        frNames: ''
      },
      newFrName: '',
      rules: {
        frNames: [
          { required: true, message: 'Ce champ est requis', trigger: 'blur' },
          { validator: this.frNameValidator, trigger: 'blur' }
        ],
        name: [{ required: true, message: 'Ce champ est requis', trigger: 'blur' }],
        fao: [
          { required: true, message: 'Ca champ est requis', trigger: 'blur' },
          { validator: this.faoValidator, trigger: 'blur' }
        ]
      },
      title: 'Ajouter une espèce',
      loading: false
    }
  },
  watch: {
    species (val, old) {
      if (val && !old) {
        this.load()
      }
    }
  },
  computed: {
    ...mapState(['user', 'species'])
  },
  methods: {
    submitForm (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          if (this.$route.params.fao === 'add') {
            this.$store.dispatch('createSpecies', this.form)
              .then(() => {
                this.$refs[ref].resetFields()
                this.$notify({
                  title: 'Succès',
                  message: 'Espèce ajoutée',
                  type: 'success'
                })
              })
              .catch((err) => {
                if (err.message.toString().match(/409/)) {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Ce nom existe déjà.',
                    type: 'error'
                  })
                } else {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Une erreur est survenue',
                    type: 'error'
                  })
                }
              })
          } else {
            this.$store.dispatch('updateSpecies', this.form)
              .then(() => {
                this.$notify({
                  title: 'Succès',
                  message: 'Espèce modifié',
                  type: 'success'
                })
                this.$router.push('/species')
              })
              .catch((err) => {
                console.log(err)
                this.$notify({
                  title: 'Erreur',
                  message: 'Une erreur est survenue',
                  type: 'error'
                })
              })
          }
        }
      })
    },
    load () {
      const fao = this.$route.params.fao
      if (fao && fao !== 'add') {
        if ((this.species || []).length > 0) {
          const species = this.species.find((el) => el.fao === fao)
          if (species) {
            this.form = { ...species }
            this.title = 'Modifier l\'espèce'
          } else {
            this.$notify({
              title: 'Erreur',
              message: 'Cette espèce n\'a pas pu être trouvée',
              type: 'error'
            })
          }
        }
      }
    },
    frNameValidator (rule, value, callback) {
      if (value.length < 3) {
        callback(new Error('3 caractères min.'))
      } else {
        callback()
      }
    },
    faoValidator (rule, value, callback) {
      if (!value) {
        callback()
      } else if (value.length === 3) {
        if (!value.match(/^[a-zA-Z]{3}$/)) {
          callback(new Error('Uniquement des lettres'))
        } else {
          callback()
        }
        callback()
      } else if (value.length < 3) {
        callback(new Error('3 caractères min.'))
      } else if (value.length > 3) {
        callback(new Error('3 caractères max.'))
      }
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang='scss'>
  #species-add {
    flex-direction: column;
    .el-page-header {
      margin: 20px 0;
    }
    .content {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 80px;
      .el-form {
        .el-form-item.button-container .el-form-item__content{
          display: flex;
          justify-content: center!important;
          align-items: center;
          margin-top: 40px;
        }
        .fr-names-input {
          width: 100%;
          display: flex!important;
          gap: 10px;
        }
        .fr-names-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          gap: 10px;
          .fr-name {
            .el-card__body {
              padding: 2px 0 2px 15px;
              display: flex;
              justify-content: space-between;
            }
          }
        }
        .fr-name-empty {
          font-size: 12px;
          margin: 0 10px;
          line-height: 26px;
        }
      }
    }
  }
</style>

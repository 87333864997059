const Vue = require('vue')

export default {
  createUser ({ commit }, data) {
    return Vue.api.post('users', data)
  },
  getUsers (ctx, data) {
    return Vue.api.get('users?search=' + (data.search || '') + '&page=' + (data.page || 1)).then((res) => res.data)
  },
  getSingleUser (ctx, user) {
    return Vue.api.get('users/' + (user.id || '')).then((res) => res.data)
  },
  getUserReviews (ctx, userId) {
    return Vue.api.get('users/' + (userId || '') + '/reviews').then((res) => res.data)
  },
  updateUser (ctx, user) {
    return Vue.api.put('users/' + (user.id || ''), user)
  },
  deleteUser (ctx, user) {
    return Vue.api.delete('users/' + (user.id || ''))
  },
  getUsersSalers (ctx, data) {
    return Vue.api.get('users/salers').then((res) => res.data)
  },
  addRangeScan (ctx, data) {
    return Vue.api.put('users/' + (data.userId || '') + '/scans', data).then((res) => res.data)
  },
  deleteRangeScan (ctx, data) {
    return Vue.api.delete('users/' + (data.userId || '') + '/scans', { data }).then((res) => res.data)
  }
}

<template>
  <el-container id="admin-add">
    <el-page-header @back="$router.push('/admins')">
      <template #content>
        <span class="text-large font-600 mr-3">{{ title }}</span>
      </template>
    </el-page-header>
    <div class="content">
      <el-form :model="form" ref="adminAdd" label-position="top" style="width: 400px" :rules="rules">
        <el-form-item prop="email" label="Email">
          <el-input v-model="form.email" placeholder="Email" :disabled="!!form.id"/>
        </el-form-item>
        <el-form-item prop="role" label="Rôle">
          <el-select v-model="form.role" style="width: 100%;" placeholder="Type de l'admin">
            <el-option v-for="(role, index) in roleName" :key="index" :value="index" :label="role" />
          </el-select>
        </el-form-item>
        <el-form-item prop="active" label="Status du compte">
          <el-switch
            v-model="form.active"
            class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
          />
          <span class="switch-text">Compte {{ form.active ? 'activé' : 'désactivé'}}</span>
        </el-form-item>
        <el-form-item prop="updatePassword" label="Mot de passe">
          <el-input v-model="form.updatePassword" placeholder="Mot de passe" type="password" show-password/>
        </el-form-item>
        <el-form-item class="button-container">
          <el-button type="primary" @click="submitForm('adminAdd')">
            Valider
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-container>
</template>

<script>
import { utils } from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [utils],
  data () {
    return {
      form: {
        email: '',
        role: '1',
        active: true,
        updatePassword: ''
      },
      title: 'Ajouter un admin',
      rules: {
        email: [
          { required: true, message: 'Ce champ est requis', trigger: 'blur' },
          { type: 'email', message: 'Merci d\'entrer une adresse email valide', trigger: ['blur', 'change'] }
        ],
        role: [
          { required: true, message: 'Ce champ est requis', trigger: 'blur' }
        ],
        updatePassword: [
          { required: this.$route.params.id === 'add', message: 'Ce champ est requis', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['user', 'admins'])
  },
  watch: {
    user (val) {
      if (val) {
        this.load()
      }
    }
  },
  methods: {
    submitForm (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          if (this.$route.params.id === 'add') {
            this.$store.dispatch('createAdmin', { ...this.form, password: this.form.updatePassword, updatePassword: undefined })
              .then(() => {
                this.$refs[ref].resetFields()
                this.$notify({
                  title: 'Succès',
                  message: 'Admin ajouté',
                  type: 'success'
                })
              })
              .catch((err) => {
                if (err.message.toString().match(/409/)) {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Cet email existe déjà.',
                    type: 'error'
                  })
                } else if (err.message.toString().match(/422/)) {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Email invalide',
                    type: 'error'
                  })
                } else {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Une erreur est survenue',
                    type: 'error'
                  })
                }
              })
          } else {
            this.$store.dispatch('updateAdmin', this.form)
              .then((res) => {
                this.$notify({
                  title: 'Succès',
                  message: 'Admin modifié',
                  type: 'success'
                })
              })
              .catch((err) => {
                if (err.message.toString().match(/422/)) {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Email invalide',
                    type: 'error'
                  })
                } else {
                  this.$notify({
                    title: 'Erreur',
                    message: 'Une erreur est survenue',
                    type: 'error'
                  })
                }
              })
          }
        }
      })
    },
    load () {
      const id = this.$route.params.id
      if (id && id !== 'add') {
        this.$store.dispatch('getSingleAdmin', { id })
          .then((admin) => {
            this.form = { ...admin }
            this.title = 'Modifier l\'admin'
          })
          .catch(() => {
            this.$notify({
              title: 'Erreur',
              message: 'Cet admin n\'a pas pu être trouvé',
              type: 'error'
            })
          })
      }
    }
  },
  mounted () {
    if (this.user) {
      this.load()
    }
  }
}
</script>

<style lang='scss'>
  #admin-add {
    flex-direction: column;
    .el-page-header {
      margin: 20px 0;
    }
    .content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .el-form {
        .switch-text {
          margin-left: 15px;
        }
        .el-form-item.button-container .el-form-item__content{
          margin-top: 30px;
          display: flex;
          justify-content: center!important;
          align-items: center;
        }
      }
    }
  }
</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '@/views/Login'
import Users from '@/views/Users'
import UserAdd from '@/views/UserAdd'
import Admins from '@/views/Admins'
import AdminsAdd from '@/views/AdminsAdd'
import Products from '@/views/Products'
import ProductEdit from '@/views/ProductEdit'
import Species from '@/views/Species'
import SpeciesAdd from '@/views/SpeciesAdd'

import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

const routes = [
  {
    path: '/',
    redirect: '/products'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/users/:id',
    name: 'UserAdd',
    component: UserAdd
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/admins/:id',
    name: 'AdminsAdd',
    component: AdminsAdd
  },
  {
    path: '/admins',
    name: 'Admins',
    component: Admins
  },
  {
    path: '/products/:id',
    name: 'ProductEdit',
    component: ProductEdit
  },
  {
    path: '/products',
    name: 'Produtcs',
    component: Products
  },
  {
    path: '/species/:fao',
    name: 'SpeciesAdd',
    component: SpeciesAdd
  },
  {
    path: '/species',
    name: 'Species',
    component: Species
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !cookies.get('token')) next({ path: '/login' })
  else next()
})
export default router

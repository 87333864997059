import moment from 'moment'

const __months = 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_')
export default {
  data () {
    return {
      roleName: {
        1: 'Admin'
      },
      detailLabels: {
        general: 'Aspect général',
        evisceration: 'Eviscération',
        freshness: 'Fraicheur',
        gillColor: 'Couleur des branchies',
        mouthFin: 'Gueule et nageoires',
        fleshColor: 'Couleur de la chair'
      }
    }
  },
  methods: {
    clientTypeName: (type) => {
      switch (type) {
        case '0':
          return 'Producteur'
        case '1':
          return 'Mareyeur'
        case '2':
          return 'Acheteur'
        default:
          return '-'
      }
    },
    getAdminRole (role) {
      if (parseInt(role) !== 'NaN') {
        return this.roleName[role]
      }
      return '-'
    },
    formattedDate (date, format = 'DD/MM/YYYY') {
      moment.updateLocale('fr', { months: __months })
      if (typeof date === 'number') {
        return moment.unix(date).format(format)
      }
      return moment(date).format(format)
    },
    getScanCount (firstEl, lastEl) {
      const first = parseInt(firstEl.slice(0, -1) || 0)
      const last = parseInt(lastEl.slice(0, -1) || 0)
      const count = last - first
      if (last === first && last === 0) return
      if (last === first) return 1
      return count + 1
    },
    speciesFormattedName (str) {
      if ((str.length || '').length === 0) return str
      return str[0].toUpperCase() + str.slice(1, str.length)
    },
    getMediaLink (id, ext) {
      if (process.env.VUE_APP_API) {
        return `${process.env.VUE_APP_API}/api/admin/media/${id}.${ext}?token=${this.token}`
      }
      return ''
    },
    getDetailLabel (key) {
      return this.detailLabels[key]
    }
  }
}

<template>
  <el-dialog
    title="Ajouter une série de code barre"
    v-model="visible"
    width="400px"
    append-to-body
    id="add-range"
  >
    <el-form class="content" ref="formCode" :rules="rules" :model="form">
      <el-form-item prop="first" label="Premier">
        <el-input v-model="form.first"/>
      </el-form-item>
      <el-form-item prop="nbCode" label="Nombre" required>
        <el-input-number v-model="form.nbCode" :min="1"/>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="validate">Valider</el-button>
    </template>

  </el-dialog>
</template>

<script>
import { utils } from '@/mixins'

export default {
  props: {
    userId: {
      type: String,
      default: ''
    }
  },
  mixins: [utils],
  data () {
    return {
      visible: false,
      loading: false,
      form: {
        first: '',
        nbCode: 1
      },
      rules: {
        first: [
          { required: true, message: 'Veuillez entrer le premier code barre' },
          { min: 8, max: 8, message: 'Veuillez entrer un code valide (8 chiffres)' },
          { validator: this.isInt, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    isInt (rule, value, callback) {
      if (isNaN(parseInt(value))) {
        return callback(new Error('Veuillez entrer un code valide'))
      }
      return callback()
    },
    validate () {
      this.$refs.formCode.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('addRangeScan', { userId: this.userId, ...this.form })
            .then((res) => {
              this.$emit('addedRange', res)
              this.loading = false
              this.visible = false
            })
            .catch((err) => {
              this.loading = false
              if (!err.toString().match(/cancel/)) {
                this.$notify({
                  title: 'Erreur',
                  message: 'Impossible d\'ajouter ces codes barres',
                  type: 'error'
                })
              }
            })
        } else {
          this.$notify({
            title: 'Erreur',
            message: 'Les champs ne sont pas valides',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style lang='scss'>
</style>

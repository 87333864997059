export default {
  addSpecies (state, species) {
    state.species.unshift(species)
  },
  deleteSpecies (state, speciesFao) {
    state.species = state.species.filter((s) => s.fao !== speciesFao)
  },
  updateSpecies (state, species) {
    for (let i = 0; i < state.species.length; i++) {
      if (state.species[i].fao === species.fao) {
        state.species[i] = species
      }
    }
  }
}

export default {
  addAdmin (state, admin) {
    state.admins.push(admin)
  },
  deleteAdmin (state, adminId) {
    state.admins = state.admins.filter((u) => u.id !== adminId)
  },
  updateAdmin (state, admin) {
    for (let i = 0; i < state.admins.length; i++) {
      if (state.admins[i].id === admin.id) {
        state.admins[i] = admin
      }
    }
  }
}

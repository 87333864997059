<template>
  <div class="reviews">
      <div v-if="reviews.length > 0" class="reviews-container">
        <el-card v-for="review in reviews" class="review" :key="review.id">
          <span class="delete" @click="deleteReview(review.id)">
            <CircleCloseFilled color="#f56c6c"/>
          </span>
          <div v-if="isUser">
            <h3 class="product-id" @click="$router.push('/products?search=' + review.fishId)">Produit: {{ review.fishId }}</h3>
          </div>
          <div class="header">
            <div class="content">
              <p class="name is-clickable" v-if="isProduct" @click="$router.push('/users?search=' + review.client.email)">{{ review.client.lastName }} {{ review.client.firstName[0] }}.</p>
              <p class="name" v-else>{{ review.client.lastName }} {{ review.client.firstName[0] }}.</p>
              <span class="date">{{ formattedDate(review.created, 'DD MMMM YYYY à HH:mm') }}</span>
            </div>
            <div class="content">
              <el-rate
                v-model="review.stars"
                disabled
                :colors="['#ae794a', '#ae794a', '#ae794a']"
                class="ratting-stars"
              />
            </div>
          </div>
          <div class="body">
            <div v-if="review.message && review.message.length > 0">
              <el-divider/>
              <p>{{ review.message }}</p>
            </div>
            <div v-if="review.details && Object.keys(review.details).length > 0">
              <el-divider/>
              <el-collapse :v-model="1" accordion class="details">
                <el-collapse-item title="Détails" name="1">
                  <div class="review-details" v-for="(details, key) in review.details" :key="key">
                    <p class="name">{{ getDetailLabel(key) }}</p>
                    <p class="content">{{ details.join(', ') }}</p>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="medias-container">
              <div class="media" v-for="media in review.medias" :key="media.id" @click="preview(getMediaLink(media.mediaId, media.extension))">
                <el-image lazy v-if="getMediaLink() !== '' " :src="getMediaLink(media.mediaId, media.extension)"/>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <p v-else>Aucune évaluation</p>
      <el-dialog :modelValue="open" append-to-body top="30px" @close="close">
      <el-image lazy :src="tempMediaLink"/>
    </el-dialog>
  </div>
</template>

<script>
import { utils } from '@/mixins'
import { mapState } from 'vuex'

export default {
  props: {
    reviews: {
      type: Array,
      default: () => ([])
    },
    isUser: {
      type: Boolean,
      default: false
    },
    isProduct: {
      type: Boolean,
      default: false
    }
  },
  mixins: [utils],
  data () {
    return {
      open: false,
      tempMediaLink: ''
    }
  },
  computed: mapState(['token']),
  methods: {
    preview (mediaLink) {
      this.tempMediaLink = mediaLink
      this.open = true
    },
    close () {
      this.open = false
    },
    deleteReview (id) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer cette évaluation ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteReview', id)
            .then(() => {
              this.$emit('update:reviews', this.reviews.filter((review) => review.id !== id))
            })
        })
        .catch((err) => {
          if (!err.toString().match(/cancel/)) {
            this.$notify({
              title: 'Erreur',
              message: 'Cette évaluation n\'a pas pu être supprimée',
              type: 'error'
            })
          }
        })
    }
  }
}
</script>

<style lang='scss' scoped>
  .reviews {
    width: 100%;
    .reviews-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
      .review {
        position: relative;
        width: calc(50% - 12px);
        height: fit-content;
        &:hover {
          .delete {
            opacity: 1;
          }
        }
        .product-id {
          cursor: pointer;
          margin: 0;
          &:hover {
            text-decoration: underline;
          }
        }
        .details {
          .review-details {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
            .name {
              width: 160px!important;
            }
            .content {
              max-width: calc(100% - 160px);
              text-align: right;
            }
          }
        }
        .delete {
          transition: all 0.1s ease-out;
          display: block;
          opacity: 0;
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          transform: translate(35%, -35%);
          & > svg {
            width: 22px;
          }
        }
        .header {
          display: flex;
          justify-content: space-between;
          .content {
            display: flex;
            flex-direction:column;
            margin-top: 0;
            span.date {
              font-size: 0.9em;
              color: gray;
            }
            p.name {
              margin: 0;
              &.is-clickable {
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .el-divider {
          margin: 8px auto;
        }
        .body {
          p {
            margin: 0;
            font-size: .9rem;
          }
          .medias-container {
            margin-top: 10px;
            max-width: 100%;
            display: flex;
            gap: 10px;
            .media {
              width: 50%;
              border-radius: 10px;
              overflow: hidden;
              cursor: pointer;
              .el-image {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .reviews {
    .review {
      overflow: inherit;
      .el-card__body {
        padding: 15px;
      }
      .ratting-stars {
        .el-rate__item {
          .el-rate__icon {
            margin-right: 1px;
            svg {
              width: 1.3rem;
              height: 1.3rem;
            }
          }
        }
      }
      .el-collapse-item__content, .el-collapse-item__header, .details, .el-collapse-item__wrap {
        border-color: transparent!important;
      }
    }
  }
</style>

<template>
  <el-container direction="vertical" id="species">
    <h1>Espèces</h1>
    <div class="header">
      <div class="search">
        <el-input
          class="searchbar"
          type="text"
          placeholder="Recherche"
          v-model="search"
          clearable
        />
      </div>
      <el-button type="primary" @click="$router.push('/species/add')">Ajouter une espèce</el-button>
    </div>
    <div class="table-container" v-if="(species || []).length > 0">
      <el-table
        :data="filteredSpecies"
        style="width: 100%"
        :default-sort="{ prop: 'frNames' }"
      >
        <el-table-column prop="fao" label="Code FAO" sortable>
          <template #default="scope">
            {{ scope.row.fao.toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Nom">
          <template #default="scope">
            {{ speciesFormattedName(scope.row.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="asfis" label="Dénomination">
          <template #default="scope">
            <span v-if="(scope.row.frNames || '').length > 0">{{ scope.row.frNames }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="asfis" label="Nom ASFIS">
          <template #default="scope">
            <span v-if="(scope.row.asfis || '').length > 0">{{ scope.row.asfis }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" width="180">
          <template #default="scope">
            <div class="table-buttons">
              <el-button type="default" size="small" @click="$router.push('/species/' + scope.row.fao)">Modifier</el-button>
              <el-button type="danger" size="small" @click="handleDelete(scope.row.fao)">Supprimer</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template v-else>
      <p class="table-message">Aucune donnée</p>
    </template>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import { utils } from '@/mixins'

export default {
  mixins: [utils],
  data () {
    return {
      search: ''
    }
  },
  computed: {
    filteredSpecies () {
      return this.species.filter((s) => {
        if (this.search.length === 0) {
          return true
        }
        return (s.asfis && s.asfis.toLowerCase().match(this.search.toLowerCase())) || s.fao.toLowerCase().match(this.search) || s.name.toLowerCase().match(this.search) || s.frNames.toLowerCase().match(this.search)
      })
    },
    ...mapState(['user', 'species'])
  },
  methods: {
    handleDelete (fao) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer cette espèce ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteSpecies', { fao })
        })
        .catch((err) => {
          if (!err.toString().match(/cancel/)) {
            this.$notify({
              title: 'Erreur',
              message: 'Cette espèce n\'a pas pu être supprimée',
              type: 'error'
            })
          }
        })
    }
  }
}
</script>

<style lang='scss' scoped>
  #species {
    .header {
      display: flex;
      justify-content: space-between;
      .search {
        display: flex;
        width: 50%;
        gap: 20px;
        .searchbar {
          width: 50%;
        }
        .el-button {
          width: auto;
        }
      }
    }
    .table-container {
      margin-top: 20px;
    }
    .el-pagination{
      margin-top: 20px;
      justify-content: center;
    }
    .table-buttons {
      display: flex;
    }
    .table-message {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }
  }
</style>

<template>
  <el-container direction="vertical" id="users">
    <h1>Utilisateur</h1>
    <div class="header">
      <div class="search">
        <el-input
          class="searchbar"
          type="text"
          placeholder="Recherche"
          v-model="search"
          clearable
          @keyup.enter="resetPage"
          @clear="resetPage"
        />
        <el-button type="primary" class="search" @click="resetPage">Rechercher</el-button>
      </div>
      <el-button type="primary" @click="$router.push('/users/add')">Ajouter un utilisateur</el-button>
    </div>
    <div class="table-container" v-if="users.length > 0">
      <el-table
        v-loading="loadingData"
        :data="users"
        style="width: 100%"
      >
        <el-table-column prop="email" label="Email" width="250"/>
        <el-table-column prop="firstName" label="Prénom" sortable :sort-method="sortByFirstName"/>
        <el-table-column prop="lastName" label="Nom" sortable :sort-method="sortByLastName"/>
        <el-table-column prop="phone" label="Téléphone" width="120">
          <template #default="scope">
            <span v-if="scope.row.phone">{{ scope.row.phone }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="Type d'utilisateur" width="150">
          <template #default="scope">
            {{ clientTypeName(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="reviews" label="Nombre d'évaluation" width="200" sortable>
          <template #default="scope">
            <el-button text  type="primary" v-if="scope.row.reviews > 0" @click="$refs.userReviews.open(scope.row.id)" class="clickable-reviews">{{ scope.row.reviews }} évaluation{{ scope.row.reviews > 1 ? 's' : '' }}</el-button>
            <span v-else>0 évaluation</span>
          </template>
        </el-table-column>
        <el-table-column prop="points" label="Nombre de points" width="180" sortable>
          <template #default="scope">
            <span>{{ scope.row.points || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" width="180">
          <template #default="scope">
            <div class="table-buttons">
              <el-button type="default" size="small" @click="$router.push('/users/' + scope.row.id)">Modifier</el-button>
              <el-button type="danger" size="small" @click="handleDelete(scope.row.id)">Supprimer</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-loading="loadingData"
        v-model:currentPage="page"
        background
        :page-size="25"
        layout="prev, pager, next"
        :total="count"
        hide-on-single-page
        @update:currentPage="load"
      />
    </div>
    <template v-else-if="users.length < 1 && loadingData">
      <p class="table-message">Chargement...</p>
    </template>
    <template v-else>
      <p class="table-message">Aucune donnée</p>
    </template>
    <ModalReviews ref="userReviews" :isUser="true"/>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import { utils } from '@/mixins'
import ModalReviews from '@/components/modalReviews.vue'

export default {
  mixins: [utils],
  components: {
    ModalReviews
  },
  data () {
    return {
      users: [],
      search: '',
      page: 1,
      count: null,
      loadingData: false
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.load(this.page)
      }
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    load (page) {
      this.loadingData = true
      this.$store.dispatch('getUsers', { search: this.search, page })
        .then((res) => {
          this.users = res.users
          this.count = res.count
          this.loadingData = false
        }).catch(() => {
          this.loadingData = false
          this.$notify({
            title: 'Erreur',
            message: 'Les utilisateurs n\'ont pas pu être chargés',
            type: 'error'
          })
        })
    },
    resetPage () {
      this.page = 1
      this.load(1)
    },
    handleDelete (id) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteUser', { id })
            .then(() => {
              this.load(1)
            })
        })
        .catch((err) => {
          if (!err.toString().match(/cancel/)) {
            this.$notify({
              title: 'Erreur',
              message: 'Cet utilisateur n\'a pas pu être supprimé',
              type: 'error'
            })
          }
        })
    },
    sortByFirstName (a, b) {
      return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
    },
    sortByLastName (a, b) {
      return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())
    }
  },
  created () {
    if (this.$route.query.search) {
      this.search = this.$route.query.search
    }
    if (this.user) {
      this.load(this.page)
    }
  }
}
</script>

<style lang='scss' scoped>
  #users {
    .header {
      display: flex;
      justify-content: space-between;
      .search {
        display: flex;
        width: 50%;
        gap: 20px;
        .searchbar {
          width: 50%;
        }
        .el-button {
          width: auto;
        }
      }
    }
    .table-container {
      margin-top: 20px;
    }
    .el-pagination{
      margin-top: 20px;
      justify-content: center;
    }
    .table-buttons {
      display: flex;
    }
    .table-message {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }
    .clickable-reviews {
      padding: 0;
    }
  }
</style>

<template>
  <el-container direction="vertical" id="admins">
    <h1>Admins</h1>
    <div class="header">
      <div class="search">
        <el-input
          class="searchbar"
          type="text"
          placeholder="Recherche"
          v-model="search"
          clearable
        />
      </div>
      <el-button type="primary" @click="$router.push('/admins/add')">Ajouter un admin</el-button>
    </div>
    <div class="table-container" v-if="filteredAdmins.length > 0">
      <el-table
        v-loading="loadingData"
        :data="filteredAdmins"
        style="width: 100%"
      >
        <el-table-column prop="email" label="Email" width="300"/>
        <el-table-column prop="role" label="Rôle">
          <template #default="scope">
            {{ getAdminRole(scope.row.role) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" width="180">
          <template #default="scope">
            <div class="table-buttons">
              <el-button type="default" size="small" @click="$router.push('/admins/' + scope.row.id)">Modifier</el-button>
              <el-button type="danger" size="small" @click="handleDelete(scope.row.id)">Supprimer</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template v-else-if="filteredAdmins.length < 1 && loadingData">
      <p class="table-message">Chargement...</p>
    </template>
    <template v-else>
      <p class="table-message">Aucune donnée</p>
    </template>
  </el-container>
</template>

<script>
import { utils } from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [utils],
  data () {
    return {
      search: '',
      loadingData: false
    }
  },
  computed: {
    filteredAdmins () {
      if (this.search.length > 0) {
        return this.admins.filter((a) => {
          return a.email.match(this.search)
        })
      }
      return this.admins || []
    },
    ...mapState(['user', 'admins'])
  },
  methods: {
    handleDelete (id) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer cet admin ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteAdmin', { id })
        })
        .catch((err) => {
          if (!err.toString().match(/cancel/)) {
            this.$notify({
              title: 'Erreur',
              message: 'Cet admin n\'a pas pu être supprimé',
              type: 'error'
            })
          }
        })
    }
  }
}
</script>

<style lang='scss' scoped>
  #admins {
    .header {
      display: flex;
      justify-content: space-between;
      .search {
        display: flex;
        width: 50%;
        gap: 20px;
        .searchbar {
          width: 50%;
        }
        .el-button {
          width: auto;
        }
      }
    }
    .table-container {
      margin-top: 20px;
    }
    .el-pagination{
      margin-top: 20px;
      justify-content: center;
    }
    .table-buttons {
      display: flex;
    }
    .table-message {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }
  }
</style>

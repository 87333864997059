const Vue = require('vue')

export default {
  createSpecies ({ commit }, data) {
    return Vue.api.post('species', data).then((res) => commit('addSpecies', res.data))
  },
  getSpecies ({ commit }, data) {
    return Vue.api.get('species').then((res) => commit('init', { species: res.data }))
  },
  updateSpecies ({ commit }, species) {
    return Vue.api.put('species/' + (species.fao || ''), species).then((res) => commit('updateSpecies', res.data))
  },
  deleteSpecies ({ commit }, species) {
    return Vue.api.delete('species/' + (species.fao || '')).then(() => commit('deleteSpecies', species.fao))
  }
}

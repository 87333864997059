<template>
  <div id="app" v-loading="loading">
    <el-menu
      :router="true"
      :default-active="active"
      mode="horizontal"
    >
      <h2>Ikejime</h2>
      <template v-if="token">
        <el-menu-item index="/">Accueil</el-menu-item>
        <el-menu-item index="/users">Utilisateurs</el-menu-item>
        <el-menu-item index="/products">Produits</el-menu-item>
        <el-menu-item index="/species">Espèces</el-menu-item>
        <el-menu-item index="/admins">Admins</el-menu-item>
        <el-menu-item class="button">
          <el-button type="danger" @click="logout">Déconnexion</el-button>
        </el-menu-item>
      </template>
    </el-menu>
    <router-view class="container"/>
  </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  data () {
    return {}
  },
  created () {
    const token = this.$cookies.get('token')
    if (token) {
      this.$store.commit('init', { token: token.replace('/', '') })
    } else {
      this.$router.push('/login').catch(() => {})
    }
  },
  computed: {
    active () {
      return '/' + (this.$route.path || '').substring(1).split('/').shift()
    },
    ...mapState(['user', 'token', 'loading'])
  },
  watch: {
    token (value, old) {
      if (value) {
        this.$cookies.set('token', value, { path: '/' })
        setTimeout(() => this.$store.dispatch('load'), 50)

        if (this.$route.path.startsWith('/login')) {
          this.$nextTick(() => this.$router.push('/'))
        }
      } else if (old) {
        this.$cookies.remove('token')
        this.$nextTick(() => this.$router.push('/login'))
      }
    }
  },
  methods: {
    logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  }
}

</script>

<style lang="scss">
  body,html {
    margin: 0;
    padding: 0;
  }
  button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
  .el-select-dropdown__item span, .el-notification__group, .el-select-dropdown__item, .el-message-box, .el-dialog, .el-popper {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    .container {
      max-width: 1350px;
      margin: 0 auto;
      h1 {
        text-align: center;
        font-size: 2em;
      }
    }
    .el-menu {
      display: flex;
      align-items: center;
      h2 {
        margin-left: 10px;
        font-size: 20px;
        margin-top: 12px;
      }
      .el-menu-item {
        &.button {
          margin-left: auto;
          display: flex;
          height: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 1390px) {
    .el-container {
      margin: 0 20px!important;
    }
  }

</style>

const Vue = require('vue')

export default {
  getProducts ({ commit }, data) {
    return Vue.api.get('products?search=' + (data.search || '') + '&page=' + (data.page || '') + `&sortBy=${data.sort.field || 'id'}&sort=${data.sort.order || 'DESC'}`).then((res) => res.data)
  },
  getProduct ({ commit }, productId) {
    return Vue.api.get('products/' + productId).then((res) => res.data)
  },
  getProductReviews ({ commit }, productId) {
    return Vue.api.get('products/' + productId + '/reviews').then((res) => res.data)
  },
  deleteProduct ({ commit }, productId) {
    return Vue.api.delete('products/' + productId).then((res) => res.data)
  },
  deleteReview ({ commit }, reviewId) {
    return Vue.api.delete('products/reviews/' + reviewId)
  },
  updateProduct ({ commit }, product) {
    return Vue.api.put('products/' + product.id, product).then((res) => res.data)
  }
}

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/index.css'
import VueCookies from 'vue3-cookies'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import fr from 'element-plus/dist/locale/fr.mjs'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(VueGoogleMaps, { load: { key: 'AIzaSyAbXf04Jd7y-JKdIyygiyNcTVDBsrhNFJk' } })
app.use(ElementPlus, { size: 'default', zIndex: 3000, locale: fr })
app.use(VueCookies, { expireTimes: '30d', path: '/' })
app.use(store).use(router).mount('#app')

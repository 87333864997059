const Vue = require('vue')

export default {
  signin ({ commit }, data) {
    return Vue.api.post('login/signin', data)
      .then((res) => commit('init', { token: res.data }))
  },
  getUser ({ commit }) {
    return Vue.api.get('me').then((res) => commit('init', { user: res.data }))
  },
  getAdmins ({ commit }) {
    return Vue.api.get('admins').then((res) => commit('init', { admins: res.data }))
  },
  createAdmin ({ commit }, admin) {
    return Vue.api.post('admins', admin).then((res) => commit('addAdmin', res.data))
  },
  getSingleAdmin (ctx, admin) {
    return Vue.api.get('admins/' + (admin.id || '')).then(res => res.data)
  },
  deleteAdmin ({ commit }, admin) {
    return Vue.api.delete('admins/' + (admin.id || '')).then((res) => commit('deleteAdmin', res.data))
  },
  updateAdmin ({ commit }, admin) {
    return Vue.api.put('admins/' + admin.id, admin).then((res) => commit('updateAdmin', res.data))
  }
}
